<template>
	<div class="lotteryOrder el-content">
		<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
			{title:'ID',dataIndex:'id'},
			{title:'中奖用户信息',dataIndex:'user',slots:{customRender:'user'}},
			{title:'奖品名称',dataIndex:'goods',slots:{customRender:'goods'}},
			{title:'奖品类型',dataIndex:'type',slots:{customRender:'type'}},
			{title:'中奖时间',dataIndex:'create_time'},
			{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
		]" >
			<template #user="{record}">
				<div v-if="record.user">
					<a-avatar size="small" :src="record.user.avatar_url"/>
					<div>用户UID：{{record.user.user_id}}</div>
					<div>用户昵称：{{record.user.nickname}}</div>
					<div>联系电话：{{record.user.mobile}}</div>
				</div>
			</template>
			<template #goods="{record}">
				<a-avatar shape="square" size="small" :src="prizeName(record.other).img_url"/>
				<div>奖品名称：{{prizeName(record.other).title}}</div>
				<div v-if="prizeName(record.other).good_id">奖品id：{{prizeName(record.other).good_id}}</div>
				<div v-if="record.order_id">订单id：{{record.order_id}}</div>
			</template>
			<template #type="{record}">
				<span>{{prizeType(record.order_type)}}</span>
			</template>
			<template #action="{record}">
				<router-link :to="{path:'/order/detail',query:{id:record.order_id}}">
					<kd-button v-if="record.order_id" type="primary" title="点击查看订单详情" icon="ri-file-list-2-line"></kd-button>
				</router-link>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange="(p,e)=>{getOrderList(info.page,e)}"
				@change="(e)=>{getOrderList(e,info.limit)}"
			/>
		</div>
	</div>
</template>

<script>
import { computed, reactive, toRefs } from 'vue'
import lotteryModel from '@/api/addons/lottery'
export default{
	setup(){
		const state = reactive({
			info:{
				list:[],
				count:0,
				page:1,
				limit:10
			},
		})
		const prizeName = computed(()=>{
			return function(e){
				return JSON.parse(e)
			}
		})
		const prizeType = computed(()=>{
			return function(e){
				return e == 1 ? '谢谢参与' : (e==2 ?'余额红包': (e==3?'优惠券':(e==4?'积分':'赠品')))
			}
		})
		getOrderList(1,state.info.limit)
		function getOrderList(page,limit){
			lotteryModel.getLotteryOrder(page,limit,res=>state.info = {limit,...res})
		}

		return{
			...toRefs(state),
			getOrderList,
			prizeType,
			prizeName
		}
	}
}
</script>

<style>
</style>
