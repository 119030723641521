// 九宫格抽奖
import { $post } from '@/api/http.js'
import tool from '@/util/tool.js'
import commonModel from '../common'
import router from '@/router'

class Lottery{
    /**
     * 获取抽奖列表信息
     * @param {number} page 
     * @param {number} limit 
     * @param {function} fn 
     */
    getLottery(page,limit,fn){
        let param = { page,limit } 
        commonModel.getDataList("getJiuList",param).then(res=>fn(res))
    }

    /**
     * 获取抽奖详情
     * @param {number} id 
     * @param {function} fn 
     */
    getLotteryDetail(id,fn){
        $post('getJiuDetail',{id}).then(res=>fn(res.data))
    }

    /**
     * 保存抽奖信息
     * @param {object} param 
     */
    addOrEditLottery(param){
        if( !param.id ) delete param.id
        $post("updateOrCreate",param).then(()=>{
            tool.message("保存成功")
            router.go(-1)
        }).catch(()=>{})
    }

    /**
     * 获取订单列表信息
     * @param {number} page 
     * @param {number} limit 
     * @param {function} fn 
     */
    getLotteryOrder(page,limit,fn){
        let param = { page,limit }
        commonModel.getDataList("getGiuOrderList",param).then(res=>fn(res))
    }
}
const lotteryModel =new Lottery()
export default lotteryModel